import React from "react";

export default class Toolbar extends React.Component {
  render() {
    return (
      <div id="toolbar">
        <h1>Toolbar</h1>
        <img
          src={require("./images/toolbar_1.png")}
          alt="toolbar_1"
          className="img200"
        />
        <p>
          These toolbars are quite simple and exist to hide away information
          based on type.
        </p>
        <img
          src={require("./images/toolbar_2.png")}
          alt="toolbar_2"
          className="img200"
        />
        <p>
          Notice that other entries in the toolbar begin to take their form when
          hovered upon.
        </p>

        <br />

        <p>To use the toolbar you must first import it like so:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/toolbar.jsx' %{"}\n"}
          </code>
        </div>

        <p>It consists of two pieces:</p>
        <ul>
          <li>Toolbar (container)</li>
          <li>ToolbarItem (content)</li>
        </ul>

        <p>Toolbar can take the following prop types:</p>
        <ul>
          <li>content</li>
        </ul>

        <p>And ToolbarItem can take these prop types:</p>
        <ul>
          <li>href</li>
          <li>id</li>
          <li>className</li>
          <li>onClick</li>
          <li>text</li>
          <li>active (boolean)</li>
          <li>counterContent</li>
        </ul>

        <p>
          It is best to add your ToolbarItem's in a div before passing them into
          Toolbar so that you don't throw a react error. Like so:
        </p>
        <div className="code">
          <span>&lt;Toolbar</span>
          <br />
          <span className="mar20">content={"{"}</span>
          <br />
          <span className="mar40">&lt;div&gt;</span>
          <br />
          <span className="mar60">&lt;ToolbarItem</span>
          <br />
          <span className="mar80">
            text={"{"}"Page A"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            onClick={"{"}() => this.setState({"{"} view: "Page A"
            {"}\n"}){"}\n"}
          </span>
          <br />
          <span className="mar60">/&gt;</span>
          <br />
          <span className="mar60">&lt;ToolbarItem</span>
          <br />
          <span className="mar80">
            text={"{"}"Page B"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            onClick={"{"}() => this.setState({"{"} view: "Page B"
            {"}\n"}){"}\n"}
          </span>
          <br />
          <span className="mar60">/&gt;</span>
          <br />
          <span className="mar40">&lt;/div&gt;</span>
          <br />
          <span className="mar20">{"}\n"}</span>
          <br />
          <span>/&gt;</span>
        </div>

        <br />
      </div>
    );
  }
}
