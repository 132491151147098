import React from "react";
import ColorCard from "./ColorCard.jsx";

export default class Label extends React.Component {
  render() {
    return (
      <div id="popover">
        <h1>Labels</h1>
        <img
          src={require("./images/label_example.png")}
          alt="label"
          style={{maxWidth: '1000px'}}
        />
        <p>
          The labels are similar in style to the button component. First you
          must import the component like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/label.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>text</li>
          <li>type</li>
          <li>size</li>
        </ul>
        <p>
          Labels come in two different sizes, default and large. To use the
          large size, pass it in through props:{" "}
        </p>
        <ul>
          <li>lg</li>
        </ul>
        <p>The labels have 6 different types which can be passed in:</p>
        <div style={{display: 'flex'}}>
          <ColorCard bcolor={"#6a6a6a"} handle={"default"} />
          <ColorCard bcolor={"#006aa1"} handle={"primary"} />
          <ColorCard bcolor={"#4bb37a"} handle={"success"} />
          <ColorCard bcolor={"#23b7e5"} handle={"info"} />
          <ColorCard bcolor={"#ef9925"} handle={"warning"} />
          <ColorCard bcolor={"#e24e26"} handle={"danger"} />
        </div>

        <div className="code">
          <span>
            &lt;Label text={"{"}"label text"{"}\n"} size={"{"}"lg"{"}\n"} type={"{"}"primary"{"}\n"}/&gt;
          </span>
        </div>
        <br />
      </div>
    );
  }
}
