import React from "react";

export default class DetailBlock extends React.Component {
  render() {
    return (
      <div id="detail_box">
        <h1>DetailBlock</h1>
        <img
          src={require("./images/detail_box.png")}
          alt="switch"
          className="img200"
        />
        <p>
          The DetailBlock component is used to make these blocks on detail
          pages, such as the campaign detail page for example.
        </p>
        <p>
          This actually consists of four components which must work together:
        </p>
        <ul>
          <li>DetailBlock</li>
          <li>DetailHead</li>
          <li>DetailSubHead (for toolbars etc.)</li>
          <li>DetailRow</li>
        </ul>
        <div className="code">
          <code>
            {"{"}% include 'common/react/detail_block.jsx' %{"}\n"}
          </code>
        </div>

        <p>You can use the following prop types:</p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>DetailBlock:</p>
            <ul>
              <li>head</li>
              <li>blockID</li>
              <li>innerRows</li>
            </ul>
          </div>

          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>DetailHead:</p>
            <ul>
              <li>blockNum</li>
              <li>title</li>
              <li>desc</li>
            </ul>
          </div>

          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>DetailSubHead:</p>
            <ul>
              <li>headContent</li>
            </ul>
          </div>

          <div style={{ padding: "15px" }}>
            <p>DetailRow:</p>
            <ul>
              <li>labelText</li>
              <li>rowContent</li>
            </ul>
          </div>
        </div>
        <br />
        <div className="code">
          <span>&lt;DetailBlock head={"{"}</span>
          <br />
          <span className="mar20">&lt;div&gt;</span>
          <br />
          <span className="mar40">
            &lt;DetailHead blockNum={"{"}1{"}\n"} title={"{"}"Title"{"}\n"}{" "}
            desc=
            {"{"}"This is a description"{"}\n"} /&gt;
          </span>
          <br />
          <span className="mar40">
            &lt;DetailSubHead headContent={"{"}&lt;Toolbar /&gt;{"}\n"} /&gt;
          </span>
          <br />
          <span className="mar20">&lt;/div&gt;</span>
          <br />
          <span>{"}\n"}</span>
          <br />
          <span>innerRows={"{"}</span>
          <br />
          <span className="mar20">&lt;div&gt;</span>
          <br />
          <span className="mar40">
            &lt;DetailRow labelText={"{"}"Label Text:"{"}\n"} rowContent={"{"}
            &lt;Input /&gt;
            {"}\n"} /&gt;
          </span>
          <br />
          <span className="mar20">&lt;/div&gt;</span>
          <br />
          <span>{"}\n"} /&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
