import React from "react";

export default class RightMenu extends React.Component {
  render() {
    return (
      <div id="right_menu">
        <h1>Right Menu</h1>
        <img
          src={require("./images/right_menu.png")}
          alt="switch"
          //   className="img100"
        />
        <p>
          The right menu remains hidden by default and when toggled by the user
          it will slide out and grant access to options that were previously
          hidden. To use it, you must first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/right_menu.jsx' %{"}\n"}
          </code>
        </div>

        <p>
          {" "}
          The right menu consists of four components that must be used in unison,
          and they can take the following prop types:
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>RightMenu:</p>
            <ul>
              <li>searchArea</li>
              <li>setInactive</li>
              <li>groupID</li>
              <li>group</li>
            </ul>
          </div>

          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>RightMenuSearch:</p>
            <ul>
              <li>label</li>
              <li>content</li>
            </ul>
          </div>

          <div style={{ padding: "15px", borderRight: "1px solid black" }}>
            <p>RightMenuGroup:</p>
            <ul>
              <li>groupName</li>
              <li>groupItems</li>
            </ul>
          </div>

          <div style={{ padding: "15px" }}>
            <p>RightMenuGroupItem:</p>
            <ul>
              <li>itemID</li>
              <li>href</li>
              <li>onClick</li>
              <li>text</li>
            </ul>
          </div>
        </div>
        <br />
        <p>You should use React's state to toggle the view of this component.</p>
        <br />
        <div className="code">
          <span>{"{"}this.state.rmenu === true ? (</span>
          <br />
          <span>
            &lt;RightMenu setInactive={"{"}() => this.setState({"{"}rmenu: false
            {"}\n"}){"}\n"} /&gt;
          </span>
          <br />
          <span className="mar20">
            &lt;RightMenuSearch label={"{"}"LABEL"
            {"}\n"} content={"{"}&lt;Input /&gt;
            {"}\n"} /&gt;
          </span>
          <br />
          <span>
            {"}\n"} listGroup={"{"}
          </span>
          <br />
          <span className="mar20">&lt;div&gt;</span>
          <br />
          <span className="mar40">
            &lt;RightMenuGroup groupName={"{"}"GROUP"{"}\n"} groupItems={"{"}
            &lt;RightMenuGroupItem text={"{"}"ITEM"{"}\n"} /&gt;
            {"}\n"} /&gt;
          </span>
          <br />
          <span className="mar20">&lt;/div&gt;</span>
          <br />
          <span>{"}\n"} /&gt;</span>
          <br />
          <span>) : ''{"}\n"}</span>
        </div>
        <br />
      </div>
    );
  }
}
