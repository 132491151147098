import React from "react";
import SynalioLogo from "./SynalioLogo.jsx";

export default class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar">
        <div className="logo" style={{ position: "fixed" }}>
          <SynalioLogo />
        </div>
        <div style={{ marginTop: "50px" }}>
          <ul>
            <li>
              <h3>
                <a href="https://github.com/eijiokuda/rebot-boilerplate/blob/master/boilerplate.html">
                  Quick Start
                </a>
              </h3>
            </li>
            <li>
              <h3>Page Layout</h3>
              <ul>
                <li>
                  <a
                    onClick={() => this.props.changeView("detail_block")}
                    href="#detail_block"
                  >
                    Detail Block
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("smart_list")}
                    href="#smart_list"
                  >
                    Smart List
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h3 style={{ cursor: "default" }}>Basic</h3>
              <ul>
                <li>
                  <a
                    onClick={() => this.props.changeView("color")}
                    href="#color"
                  >
                    Color
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h3 style={{ cursor: "default" }}>UI Components</h3>
              <ul>
                <li>
                  <a
                    onClick={() => this.props.changeView("alerts")}
                    href="#alerts"
                  >
                    Alerts
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("buttons")}
                    href="#buttons"
                  >
                    Buttons
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("checkbox")}
                    href="#checkbox"
                  >
                    Checkbox
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("creative_list")}
                    href="#creative_list"
                  >
                    Creative List
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("inputs")}
                    href="#inputs"
                  >
                    Inputs
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("labels")}
                    href="#labels"
                  >
                    Labels
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("modals")}
                    href="#modals"
                  >
                    Modals
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("pagination")}
                    href="#pagination"
                  >
                    Pagination
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("popover")}
                    href="#popover"
                  >
                    Popover
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("radio_list")}
                    href="#radio_list"
                  >
                    Radio List
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("right_menu")}
                    href="#right_menu"
                  >
                    Right Menu
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("select")}
                    href="#select"
                  >
                    Select
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("switch")}
                    href="#switch"
                  >
                    Switch
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("toolbar")}
                    href="#toolbar"
                  >
                    Toolbar
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("tooltip")}
                    href="#tooltip"
                  >
                    Tooltip
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.props.changeView("tristate-switch")}
                    href="#tristate-switch"
                  >
                    TristateSwitch
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
