import React from "react";

export default class Select extends React.Component {
  render() {
    return (
      <div id="select">
        <h1>Select</h1>
        <img
          src={require("./images/select.png")}
          alt="select"
          className="img100"
        />
        <p>To use the select component, you must first import it like this:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/select.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>className</li>
          <li>id</li>
          <li>name</li>
          <li>onChange</li>
          <li>disabled (boolean)</li>
          <li>required (boolean)</li>
          <li>options (array of objects)</li>
        </ul>

        <h3>About options</h3>
        <p>
          Normally for a select element we must put &lt;options&gt; between the
          select tags, but with a react element this becomes difficult to do. So
          with this component, we can add our options to an array and pass that
          to the component.
        </p>
        <p>Options should be submitted as an object with two key-value-pairs: text and value.</p>
        <br />

        <div className="code">
          <span>&lt;Select name={"{"}"example"{"}\n"} id={"{"}"example-select"{"}\n"} options={"{"}[{"{"}text: 'One', value: 1{"}\n"}, {"{"}text: 'Two', value: 2{"}\n"}, {"{"}text: 'Three', value: 3{"}\n"}]{"}\n"} /&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
