import React from "react";

export default class QuickStart extends React.Component {
  render() {
    return (
      <div id="quick_start">
        <h1>Quick Start</h1>
        <p>Note to self: Remove this page, put the boilerplate on github. Change the Quick Start link in sidebar to a link to github.</p>
      </div>
    );
  }
}
