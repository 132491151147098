import React from "react";

export default class Popover extends React.Component {
  render() {
    return (
      <div id="smart_list">
        <h1>SmartList</h1>
        <img
          src={require("./images/smart_list.png")}
          alt="smart list"
          style={{ width: "800px" }}
        />
        <p>
          The SmartList is used on list pages in V2, usually as a kind of
          directory. To use it, you must first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/smart_list.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following props:</p>
        <ul>
          <li>head</li>
          <li>lastHead</li>
          <li>listItems</li>
        </ul>
        <br />

        <h2>Regarding the list head</h2>
        <img
          src={require("./images/list_head.png")}
          alt="smart list"
          style={{ width: "800px" }}
        />
        <p>
          Items in the list head are passed via an array which is mapped to
          create the head automatically. If you must include an smaller empty
          entry at the end of the list you can use the lastHead prop type.
        </p>
        <div className="code">
          <span>
            head={"{"}['placeholder', 'placeholder2', 'placeholder3',
            'placeholder4']{"}\n"} lastHead={"{"}true{"}\n"}
          </span>
        </div>
        <br />

        <h2>Regarding the list content</h2>
        <img
          src={require("./images/list_content.png")}
          alt="smart list"
          style={{ width: "800px" }}
        />
        <p>
          List contents are passed in as an li. You are going to need to map
          through a list to get a list item to render for every item. Due to the
          way react works, you will also need to wrap these items in a div
          element.
        </p>
        <br />

        <div className="code">
          <span>&lt;SmartList</span>
          <br />
          <span className="mar20">
            head={"{"}['placeholder', 'placeholder2', 'placeholder3',
            'placeholder4']{"}\n"}
          </span>
          <br />
          <span className="mar20">
            lastHead={"{"}true{"}\n"}
          </span>
          <br />
          <span className="mar20">listItems={"{"}</span>
          <br />
          <span className="mar40">&lt;div&gt;</span>
          <br />
          <span className="mar60">
            {"{"}this.state.array.map(i => {"{"}
          </span>
          <br />
          <span className="mar80">return (</span>
          <br />
          <span className="mar100">&lt;li&gt;</span>
          <br />
          <span className="mar120">&lt;div&gt;Placeholder&lt;/div&gt;</span>
          <br />
          <span className="mar120">&lt;div&gt;Placeholder2&lt;/div&gt;</span>
          <br />
          <span className="mar120">&lt;div&gt;Placeholder3&lt;/div&gt;</span>
          <br />
          <span className="mar120">&lt;div&gt;Placeholder4&lt;/div&gt;</span>
          <br />
          <span className="mar120">&lt;Tooltip /&gt;</span>
          <br />
          <span className="mar100">&lt;/li&gt;</span>
          <br />
          <span className="mar80">)</span>
          <br />
          <span className="mar60">{"}\n"}){"}\n"}</span>
          <br />
          <span className="mar40">&lt;/div&gt;</span>
          <br />
          <span className="mar20">{"}\n"}</span>
          <br />
          <span>&gt;></span>
          <br />
        </div>
        <br />
      </div>
    );
  }
}
