import React from "react";

export default class Modals extends React.Component {
  render() {
    return (
      <div id="modals">
        <h2>Delete Modal</h2>
        <img
          src={require("./images/modal.png")}
          alt="modal"
          style={{ height: "200px" }}
        />
        <p>
          This modal type is used to confirm if the user wants to delete
          something, they are sometimes used for other purposes also.
        </p>
        <p>You must first import it like so:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/modal.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>head (Provides the modal's header text)</li>
          <li>text (Provides the modal's main text)</li>
          <li>btn1 (Provides the modals buttons)</li>
          <li>btn2 (Provides the modals buttons)</li>
        </ul>

        <p>
          When you pass in buttons, you should pass in the{" "}
          <a
            onClick={() => this.props.changeView("buttons")}
            style={{ color: "#1978c8" }}
          >
            Button
          </a>{" "}
          component.
        </p>

        <div className="code">
          <span>
            &lt;Modal head={"{"}"HEADER TEXT"{"}\n"} text={"{"}"MAIN TEXT"
            {"}\n"} btn1={"{"}&lt;Button /&gt;{"}\n"} btn2={"{"}
            &lt;Button /&gt;{"}\n"} /&gt;
          </span>
        </div>

        <br />

        <h2>Info Modals</h2>
        <p>These modals are used in the dashboards. They look like this:</p>
        <img
          src={require("./images/tooltip_i_1.png")}
          alt="i-tooltip 1"
          style={{ height: "200px" }}
        />
        <p>
          Upon clicking, it will open a window which covers the whole page and
          provides information like this:
        </p>
        <img
          src={require("./images/tooltip_i_2.png")}
          alt="i-tooltip 2"
          style={{ height: "400px" }}
        />

        <p>You must first import it like so:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/infomodal.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>head (Provides the modal's header text)</li>
          <li>text (Provides the modal's main text)</li>
          <li>XonClick (X button's onClick</li>
        </ul>

        <div className="code">
          <span>
            &lt;InfoModal head={"{"}"HEADER TEXT"{"}\n"} text={"{"}"MAIN TEXT"
            {"}\n"} /&gt;
          </span>
        </div>

        <br />
      </div>
    );
  }
}
