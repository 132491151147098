import React from "react";

export default class SynalioLogoLarge extends React.Component {
  render() {
    return (
      <div>
        <div id="h_logo">
          <a href="#" title="トップ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500px"
              height="150px"
              viewBox="0 0 144 24.2"
            >
              <g>
                <g>
                  <path
                    className="st0"
                    d="M26.7,11.1c0-0.1,0-0.2,0-0.4c0-0.5,0.2-1,0.5-1.4c0.2-0.2,0.4-0.4,0.7-0.6l1-1.2l-5.7-6.8
								c0.7,0.9,0.6,2.2-0.3,2.9C22,4.3,20.8,4.2,20,3.4l6.8,8.1C26.8,11.3,26.7,11.2,26.7,11.1C26.7,11.1,26.7,11.1,26.7,11.1z"
                  />
                  <path
                    className="st1"
                    d="M22.9,3.6c0.9-0.7,1-2,0.3-2.9c0,0,0,0,0,0c-0.7-0.9-2-1-2.9-0.3c-0.9,0.7-1,2-0.3,2.9
								C20.8,4.2,22.1,4.3,22.9,3.6z"
                  />
                  <path
                    className="st2"
                    d="M30.4,12.3C30.4,12.3,30.4,12.3,30.4,12.3c-0.1,0.1-0.2,0.1-0.3,0.2l0,0c-0.1,0.1-0.2,0.1-0.3,0.2
								c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1l0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0
								c-0.1,0-0.2,0-0.3-0.1l0,0c-0.1,0-0.2-0.1-0.3-0.1l0,0c-0.1,0-0.2-0.1-0.3-0.2l0,0c-0.1-0.1-0.2-0.1-0.2-0.2l0,0
								c-0.2-0.2-0.4-0.5-0.5-0.8v10.6c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1V11.5C30.8,11.8,30.6,12.1,30.4,12.3z"
                  />
                  <path
                    className="st3"
                    d="M28.9,20.1c-1.1,0-2,0.9-2.1,2c0,0,0,0,0,0c0,1.1,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1
								C30.9,21,30,20.1,28.9,20.1z"
                  />
                  <path
                    className="st4"
                    d="M34.8,3.6c-0.9-0.7-1-2-0.3-2.9c0,0,0,0,0,0l-5.7,6.8l1,1.2c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.2,0.2,0.4
								c0.2,0.3,0.3,0.7,0.3,1c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.4l6.8-8.1C37,4.2,35.7,4.3,34.8,3.6z"
                  />
                  <path
                    className="st5"
                    d="M27.9,8.8c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.2,0,0.3,0,0.5,0
								c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1l-1-1.2L27.9,8.8L27.9,8.8
								z"
                  />
                  <path
                    className="st6"
                    d="M37.5,0.5c-0.9-0.7-2.2-0.6-2.9,0.3c-0.7,0.9-0.6,2.2,0.3,2.9c0.9,0.7,2.1,0.6,2.9-0.3
								C38.5,2.5,38.4,1.2,37.5,0.5z"
                  />
                  <path
                    className="st7"
                    d="M31.1,11.1c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4-0.1-0.6C31,10,31,10,30.9,9.9c0-0.1-0.1-0.1-0.1-0.2
								c-0.1-0.1-0.1-0.2-0.2-0.4C30.3,9,30,8.8,29.7,8.7l0,0c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0l0,0l0,0
								c-0.2,0-0.3,0-0.5,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0l0,0l0,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1
								c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4v0c0,0.1,0.1,0.2,0.1,0.3l0,0.1
								c0.1,0.3,0.3,0.6,0.5,0.8l0,0c0.1,0.1,0.2,0.1,0.2,0.2l0,0c0.1,0.1,0.2,0.1,0.3,0.2l0,0l0,0c0.1,0,0.2,0.1,0.3,0.1l0,0
								c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0h0c0.2,0,0.4,0,0.6,0h0l0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1l0,0
								l0,0c0.1,0,0.2-0.1,0.3-0.2l0,0l0,0c0.1-0.1,0.2-0.1,0.2-0.2l0,0l0,0c0.2-0.2,0.4-0.5,0.5-0.8c0,0,0,0,0-0.1
								C31,11.3,31,11.2,31.1,11.1L31.1,11.1z"
                  />
                  <path
                    className="st8"
                    d="M57.2,15.8L45.9,1c-0.2-0.3-0.6-0.5-0.9-0.5h-0.1c-0.6,0-1.1,0.5-1.1,1.1v20.8c0,0.6,0.5,1.1,1.1,1.1
								c0,0,0,0,0,0h1.4c0.6,0,1.1-0.5,1.1-1.1c0,0,0,0,0,0V8.7l11.3,14.7c0.2,0.3,0.6,0.4,0.9,0.4h0.1c0.6,0,1.1-0.5,1.1-1.1V2
								c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0l-1.4,0c-0.6,0-1.1,0.5-1.1,1.1L57.2,15.8z M11.9,11.2c-1-0.5-2-1-3.1-1.4C7.8,9.4,7,9,6.4,8.7
								c-0.5-0.2-0.9-0.6-1.3-1C4.8,7.4,4.7,7,4.7,6.6c0-0.7,0.3-1.3,0.8-1.7C6.2,4.4,7.1,4.1,8,4.2h0c0.7,0,1.5,0.1,2.2,0.3
								c0.4,0.1,0.8,0.2,1.1,0.4c0.2,0.1,0.6,0.2,1,0.4c0.6,0.3,1.2,0,1.5-0.5l0.5-1.1c0.3-0.6,0.1-1.3-0.5-1.5c0,0,0,0,0,0
								c-0.4-0.2-0.7-0.4-0.9-0.4c-0.6-0.3-1.3-0.5-2-0.6c-1-0.3-2.1-0.4-3.2-0.4h0c-1.2,0-2.3,0.3-3.4,0.8c-1,0.5-1.9,1.2-2.5,2.1
								c-0.6,1-0.9,2.1-0.9,3.2c0,1.1,0.3,2.3,1,3.2c0.6,0.8,1.4,1.4,2.3,1.9c1,0.5,2,0.9,3,1.3c0.8,0.3,1.7,0.7,2.4,1.1
								c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1,0.5,1.6c0,0.6-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.9-1.4,1.2c-0.6,0.3-1.3,0.4-2,0.4h0
								c-0.9,0-1.8-0.2-2.6-0.5c-0.6-0.2-1.2-0.5-1.7-0.8c-0.1-0.1-0.4-0.3-0.7-0.5c-0.5-0.4-1.2-0.3-1.6,0.3c0,0,0,0.1-0.1,0.1L0.2,20
								c-0.3,0.5-0.1,1.2,0.4,1.5c0.4,0.2,0.7,0.5,0.9,0.5c1.9,1.1,4,1.7,6.2,1.8h0c1.3,0,2.7-0.3,3.9-0.9c1.1-0.5,2.1-1.4,2.7-2.5
								c0.7-1.1,1-2.4,1-3.6c0-1.3-0.3-2.5-1-3.6C13.6,12.4,12.8,11.7,11.9,11.2z M76,0.2h-0.1c-0.5,0-0.9,0.3-1.1,0.7l-8.5,21.2
								c-0.2,0.6,0,1.3,0.6,1.5c0.1,0.1,0.3,0.1,0.4,0.1h1.4c0.5,0,0.9-0.3,1.1-0.7l2.3-5.8h7.5l2.3,5.8c0.2,0.4,0.6,0.7,1.1,0.7h1.6
								c0.6,0,1.1-0.5,1.1-1.1c0-0.1,0-0.3-0.1-0.4L77,0.9C76.9,0.5,76.4,0.2,76,0.2z M73.6,13.6l2.3-5.8l2.3,5.8H73.6z M91.1,22.5V2
								c0-0.6,0.5-1.1,1.1-1.1h1.4c0.6,0,1.1,0.5,1.1,1.1v0l0,18.1l10.1,0c0.6,0,1.1,0.5,1.1,1.1v1.4c0,0.6-0.5,1.1-1.1,1.2c0,0,0,0,0,0
								l-12.7,0C91.6,23.7,91.1,23.2,91.1,22.5L91.1,22.5z"
                  />
                  <path
                    className="st8"
                    d="M111.8,0.8h1.4c0.6,0,1.1,0.5,1.1,1.1v20.6c0,0.6-0.5,1.1-1.1,1.1h-1.4c-0.6,0-1.1-0.5-1.1-1.1V1.9
								C110.6,1.3,111.1,0.8,111.8,0.8z"
                  />
                  <path
                    className="st8"
                    d="M132.3,0.6c-6.4,0-11.7,5.2-11.7,11.7c0,6.4,5.2,11.7,11.7,11.7c6.4,0,11.7-5.2,11.7-11.7c0,0,0,0,0,0
								C144,5.8,138.8,0.6,132.3,0.6z M132.3,20.2c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9c4.4,0,7.9,3.5,7.9,7.9c0,0,0,0,0,0
								C140.3,16.6,136.7,20.2,132.3,20.2z"
                  />
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>
    );
  }
}
