import React from "react";
import ColorCard from "./ColorCard.jsx";

export default class Color extends React.Component {
  render() {
    return (
      <div id="color">
        <h1>Color</h1>
        <h3>Grays</h3>
        <div className="small-grid">
          <div className="color-card">
            <div
              className="color-preview"
              style={{
                backgroundColor: "#f5f5f5",
                color: "black",
                borderColor: "#ccc"
              }}
            >
              <span>#f5f5f5</span>
            </div>
          </div>
          <div className="color-card">
            <div
              className="color-preview"
              style={{
                backgroundColor: "#e6e6e6",
                color: "black",
                borderColor: "#ccc"
              }}
            >
              <span>#e6e6e6</span>
            </div>
          </div>
          <ColorCard bcolor={"#D0D1CB"} />
          <ColorCard bcolor={"#ccc"} />
          <ColorCard bcolor={"#b4b4b4"} />
          <ColorCard bcolor={"#696969"} />
          <ColorCard bcolor={"#323232"} />
          <ColorCard bcolor={"rgba(0,0,0,0.8)"} handle={"tooltips"} />
        </div>

        <h3>Button colors</h3>
        <div className="grid-container">
          <ColorCard bcolor={"#006aa1"} handle={"[default]"} />
          <ColorCard bcolor={"#ef9925"} handle={"export"} />
          <ColorCard bcolor={"#e24e26"} handle={"del"} />
          <ColorCard bcolor={"#4bb37a"} handle={"confirmation"} />
          <ColorCard bcolor={"#4bb37a"} handle={"preview"} />
          <ColorCard bcolor={"#23b7e5"} handle={"save"} />
          <ColorCard bcolor={"#23b7e5"} handle={"move"} />
          <ColorCard bcolor={"#006aa1"} handle={"new"} />
          <ColorCard bcolor={"#6a6a6a"} handle={"close"} />

          <div className="color-card">
            <div
              className="color-preview"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderColor: "#ccc"
              }}
            >
              <span>transparent</span>
            </div>
            <div className="color-label">
              <span>conditions-add</span>
            </div>
          </div>

          <ColorCard bcolor={"#828282"} handle={"cancel"} />
          <ColorCard bcolor={"#ef9925"} handle={"decision"} />
          <ColorCard bcolor={"#006aa1"} handle={"chat-visual-add"} />
          <ColorCard bcolor={"#006aa1"} handle={"info"} />
          <ColorCard bcolor={"#006aa1"} handle={"choice"} />
        </div>
        <h3>Chat Window Theme Colors</h3>
        <div className="grid-container">
          <ColorCard bcolor={"#e22c2c"} />
          <ColorCard bcolor={"#02a7cb"} />
          <ColorCard bcolor={"#ed6a01"} />
          <ColorCard bcolor={"#4fab17"} />
          <ColorCard bcolor={"#ab177c"} />
          <ColorCard bcolor={"#ec2f7b"} />
          <ColorCard bcolor={"#575757"} />
          <ColorCard bcolor={"#062374"} />
          <ColorCard bcolor={"#905800"} />
          <ColorCard bcolor={"#838220"} />
          <ColorCard bcolor={"#f9d615"} />
          <div className="color-card">
            <div
              className="color-preview"
              style={{
                backgroundColor: "#ffffff",
                color: "black",
                borderColor: "#ccc"
              }}
            >
              <span>#ffffff</span>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
