import React from "react";

export default class Pagination extends React.Component {
  render() {
    return (
      <div id="pagination">
        <h1>Pagination</h1>
        <img
          src={require("./images/pagination.png")}
          alt="pagination"
          className="img100"
        />
        <p>
          To use the pagination component you must first add an include
          statement in your file:
        </p>
        <div className="code">
          <span>
            {"{"}% include "common/react/paging.jsx" %{"}\n"}
          </span>
        </div>

        <br />

        <p>It can accept the following prop types:</p>
        <ul>
          <li>current_page (Integer)</li>
          <li>
            last_page (Integer)
          </li>
          <li>paging_url (String)</li>
        </ul>

        <br />

        <p>
          You must then pass the data from the backend into the prop types when
          calling the component.
        </p>
        <div className="code">
          <span>
            &lt;Paging current_page={"{"}current_page{"}\n"} last_page={"{"}
            last_page{"}\n"} paging_url={"{"}paging_url{"}\n"} /&gt;
          </span>
        </div>

        <br />
      </div>
    );
  }
}
