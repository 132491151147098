import React from "react";

export default class Tooltip extends React.Component {
  render() {
    return (
      <div id="tooltip">
        <h1>Tooltip</h1>
        <img
          src={require("./images/tooltip_1.png")}
          alt="tooltip 1"
          className="img200"
        />
        <p>To use the tooltip you must first import it like so:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/tooltip.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>content</li>
        </ul>

        <p>
          Items to be passed into the list should be using the TooltipItem
          component. It can accept the following prop types:
        </p>
        <ul>
          <li>id</li>
          <li>className</li>
          <li>onClick</li>
          <li>href</li>
          <li>name</li>
          <li>type</li>
        </ul>

        <p>
          You should put the TooltipItem's in a div wrapper when you pass them
          in to avoid a React error.
        </p>

        <div className="code">
          <span>&lt;Tooltip</span>
          <br />
          <span className="mar20">content={"{"}</span>
          <br />
          <span className="mar40">&lt;div&gt;</span>
          <br />
          <span className="mar60">
            &lt;TooltipItem id={"{"}"del"{"}\n"} onClick={"{"}() => this.delete
            {"}\n"} /&gt;
          </span>
          <br />
          <span className="mar60">
            &lt;TooltipItem id={"{"}"edit"{"}\n"} onClick={"{"}() => this.edit
            {"}\n"} /&gt;
          </span>
          <br />
          <span className="mar40">&lt;/div&gt;</span>
          <br />
          <span className="mar20">{"}\n"}</span>
          <br />
          <span>/&gt;</span>
        </div>

        <br />
      </div>
    );
  }
}
