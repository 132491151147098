import React from "react";

export default class BadgesIcons extends React.Component {
  render() {
    return (
      <div id="badges_icons">
        <h1>Badges</h1>
        <img
          src={require("./images/badge_close_btn.png")}
          alt="blose btn badge"
          className="img100"
        />
        <h3>Close Button</h3>
        <p>
          As the name suggests, this badge is for canceling and removing things.
          The close button is a child of the tab-list class and also of the
          label-disp-box class. These two classes are used separately, never
          together. The close button must always go inside of an unordered list.
          Let's view an example, this code can be found on the campaign detail
          page.
        </p>
        <div className="code">
          <span>&lt;div class="label-disp-box"&gt;</span>
          <br />
          <span className="mar20">
            &lt;ul class="dragble-list" id="LabelList1"&gt;
          </span>
          <br />
          <span className="mar40">&lt;li class="type1"&gt;</span>
          <br />
          <span className="mar60">&lt;a&gt;</span>
          <br />
          <span className="mar80">&lt;span&gt;とんかつ&lt;/span&gt;</span>
          <br />
          <span className="mar60">&lt;/a&gt;</span>
          <br />
          <span className="mar60">
            &lt;span class="close-btn"&gt;&lt;/span&gt;
          </span>
          <br />
          <span className="mar40">&lt;/li&gt;</span>
          <br />
          <span className="mar40">&lt;li class="text"&gt;</span>
          <br />
          <span className="mar60">&lt;span&gt;かつ&lt;/span&gt;</span>
          <br />
          <span className="mar40">&lt;/li&gt;</span>
          <br />
          <span className="mar40">&lt;li class="type3"&gt;</span>
          <br />
          <span className="mar60">&lt;a&gt;</span>
          <br />
          <span className="mar80">&lt;span&gt;バナナ&lt;/span&gt;</span>
          <br />
          <span className="mar60">&lt;/a&gt;</span>
          <br />
          <span className="mar60">
            &lt;span class="close-btn"&gt;&lt;/span&gt;
          </span>
          <br />
          <span className="mar40">&lt;/li&gt;</span>
          <br />
          <span className="mar40">&lt;li class="text"&gt;</span>
          <br />
          <span className="mar60">&lt;span&gt;かつ&lt;/span&gt;</span>
          <br />
          <span className="mar40">&lt;/li&gt;</span>
          <br />
          <span className="mar40">&lt;li class="type2"&gt;</span>
          <br />
          <span className="mar60">&lt;a&gt;</span>
          <br />
          <span className="mar80">&lt;span&gt;たこ焼き&lt;/span&gt;</span>
          <br />
          <span className="mar60">&lt;/a&gt;</span>
          <br />
          <span className="mar60">
            &lt;span class="close-btn"&gt;&lt;/span&gt;
          </span>
          <br />
          <span className="mar40">&lt;/li&gt;</span>
          <br />
          <span className="mar20">&lt;/ul&gt;</span>
          <br />
          <span>&lt;/div&gt;</span>
        </div>

        <p>This block of code will generate a block like this:</p>

        <img
          src={require("./images/badge_close_btn_example.png")}
          alt="blose btn badge example"
          className="img100"
        />

        <br />

        <h1>Icons</h1>
        <img
          src={require("./images/info_icon.png")}
          alt="inco icon example"
          className="img100"
        />
        <h3>Info Icon</h3>
        <p>
          The info icon gets most of it's styles from the
          .click-btn[data-btn="info"] and the .click-btn.pos-relative class
          declarations. To use it, we simply need to put the following code
          block after whichever element you want it to trail behind.
        </p>
        <div className="code">
          <span>&lt;div style="padding-top: 6px;"&gt;</span>
          <br />
          <span className="mar20">&lt;div</span>
          <br />
          <span className="mar40">class="click-btn pos-relative"</span>
          <br />
          <span className="mar40">data-btn="info"</span>
          <br />
          <span className="mar40">data-modalid="4"</span>
          <br />
          <span className="mar40">data-over="modal-info"</span>
          <br />
          <span className="mar20">&gt;</span>
          <br />
          <span className="mar40">&lt;i class="icon-info"&gt;&lt;/i&gt;</span>
          <br />
          <span className="mar20">&lt;/div&gt;</span>
          <br />
          <span>&lt;/div&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
