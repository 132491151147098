import React from "react";
import SynalioLogoLarge from "./SynalioLogoLarge.jsx";

export default class Home extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <SynalioLogoLarge />
        <h1>Components Library</h1>
        <p>(We're going to put some helpful info here)</p>
      </div>
    );
  }
}
