import React from "react";

export default class RadioList extends React.Component {
  render() {
    return (
      <div id="radio_list">
        <h1>Radio List</h1>
        <img
          src={require("./images/radiolist.png")}
          alt="radio list"
          className="img100"
        />
        <p>To use the radio list you must first import it like this:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/radio_list.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "15px" }}>
            <p>Input props:</p>
            <ul>
              <li>name</li>
              <li>id</li>
              <li>value</li>
              <li>checked</li>
            </ul>
          </div>
          <div style={{ padding: "15px", borderLeft: "1px solid black" }}>
            <p>Label props:</p>
            <ul>
              <li>for</li>
              <li>onClick</li>
              <li>className</li>
              <li>text</li>
            </ul>
          </div>
        </div>

        <p>
          When passing the RadioListItem's into the RadioList component they
          must be wrapped in a &lt;div&gt; element. That element must have a
          flex styling, whether it be via a class or inline styles.
        </p>

        <div className="code">
          <span>&lt;RadioList</span>
          <br />
          <span className="mar20">content={"{"}</span>
          <br />
          <span className="mar40">
            &lt;div style={"{"}
            {"{"}display: "flex"{"}\n"}
            {"}\n"}&gt;
          </span>
          <br />
          <span className="mar60">&lt;RadioListItem</span>
          <br />
          <span className="mar80">
            text={"{"}"ABC"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            id={"{"}"abc"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            checked={"{"}this.state.checked === "abc" ? true : false{"}\n"}
          </span>
          <br />
          <span className="mar80">
            onClick={"{"}this.setSstate({"{"} checked: "abc"
            {"}\n"}){"}\n"}
          </span>
          <br />
          <span className="mar60">/&gt;</span>
          <br />
          <span className="mar60">&lt;RadioListItem</span>
          <br />
          <span className="mar80">
            text={"{"}"XYZ"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            id={"{"}"xyz"{"}\n"}
          </span>
          <br />
          <span className="mar80">
            checked={"{"}this.state.checked === "xyz" ? true : false{"}\n"}
          </span>
          <br />
          <span className="mar80">
            onClick={"{"}this.setSstate({"{"} checked: "xyz"
            {"}\n"}){"}\n"}
          </span>
          <br />
          <span className="mar60">/&gt;</span>
          <br />
          <span className="mar40">&lt;/div&gt;</span>
          <br />
          <span className="mar20">{"}\n"}</span>
          <br />
          <span>/&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
