import React from "react";

export default class Switch extends React.Component {
  render() {
    return (
      <div id="switch">
        <h1>Switch</h1>
        <img
          src={require("./images/switch.png")}
          alt="switch"
          className="img200"
        />
        <p>To use the switch you must first import it via this tag:</p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/switch.jsx' %{"}\n"}
          </code>
        </div>

        <p>It requires the following props:</p>
        <ul>
          <li>activeText</li>
          <li>inactiveText</li>
          <li>defaultChecked (true/false)</li>
          <li>onClick</li>
        </ul>
        <p>Full use example:</p>
        <div className="code">
          <span>
            &lt;Switch activeText={"{"}"ON"{"}\n"} inactiveText={"{"}"OFF"
            {"}\n"} defaultChecked={"{"}foo === bar ? true : false{"}\n"}{" "}
            onClick={"{"}
            () => console.log("I love Resola"){"}\n"} /&gt;
          </span>
        </div>
        <br />
      </div>
    );
  }
}
