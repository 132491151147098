import React from "react";

export default class TristateSwitch extends React.Component {
  render() {
    return (
      <div id="tristate-switch">
        <h1>Tristate Switch</h1>
        <img
          src={require("./images/tristate.png")}
          alt="tristate"
          className="img200"
        />
        <p>
          This switch can have 3 different positions to enable more options to
          the user. To use it, you must first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/tristate_switch.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>
            setPosition ('position-left', 'position-middle', 'position-right')
          </li>
          <li>
            getPosition (Function to pass position info to the parent element)
          </li>
          <li>names (array)</li>
          <li>labels (array)</li>
          <li>colors (array)</li>
        </ul>
        <br />
        <p>
          The switch has three positions, and each has it's own value.
          position-left, position-middle, and position-right. This value can be
          passed to the parent component through the getPosition prop type, or a
          default position can be decided by passing the value through the
          selected prop type.
        </p>
        <img
          src={require("./images/tristate_positions.png")}
          alt="tristate"
          className="img200"
        />
        <br />
        <p>
          That being said, in the case of this component we apply our names and
          labels by passing in arrays. If you would like to use custom colors,
          those must also be passed through an array.
        </p>
        <br />
        <p>
          array[0] will apply to position-left, array[1] will apply to
          position-middle, and array[2] will apply to position-right. This is
          true for names and labels.
        </p>
        <img
          src={require("./images/tristate_positions_array.png")}
          alt="tristate"
          className="img200"
        />

        <p>
          In the case of colors, we only pass in two colors so colors[0] will
          apply to the left position, and colors[1] will apply to the right
          position.
        </p>
        <img
          src={require("./images/tristate_positions_colors.png")}
          alt="tristate"
          className="img200"
        />
        <br />
        <div className="code">
          <span>
            &lt;TristateSwitch getPosition={"{"}e => this.handlePosition(e)
            {"}\n"} names={"{"}['left', 'middle', 'right']{"}\n"} labels={"{"}
            ['A', 'B', 'C']{"}\n"} colors={"{"}['#ade5ad', '#123456', '#e6e6e6']
            {"}\n"} /&gt;
          </span>
        </div>
        <br />
      </div>
    );
  }
}
