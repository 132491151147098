import React from "react";

export default class Checkbox extends React.Component {
  render() {
    return (
      <div id="popover">
        <h1>Popover</h1>
        <img src={require("./images/smart_checkbox.png")} alt="checkbox" />
        <p>
          This component is a kind of smart checkbox. The color can be
          customized easily, and the checkall button can have an indeterminate
          state. To use it, you must first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/checkbox.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "15px" }}>
            <p>Checkbox:</p>
            <ul>
              <li>checked (boolean)</li>
              <li>id</li>
              <li>checkboxInputClassName</li>
              <li>name</li>
              <li>value</li>
              <li>disabled (boolean)</li>
              <li>inputOnChange</li>
              <li>onClick</li>
              <li>customColorClass</li>
            </ul>
          </div>
          <div style={{ padding: "15px", borderLeft: "1px solid black" }}>
            <p>Indeterminate (Checkall):</p>
            <ul>
              <li>checked (boolean)</li>
              <li>indeterminate (boolean)</li>
              <li>onClick</li>
              <li>customColorClass</li>
            </ul>
          </div>
        </div>
        <br />

        <div className="code">
          <span>&lt;IndeterminateCheckbox checked={"{"}this.state.checked.length !== 0{"}\n"} onClick={"{"}() => this.handleCheck(){"}\n"} indeterminate={"{"}this.state.setIndeterminate{"}\n"} /&gt;</span>
        </div>
        <div className="code">
            <span>&lt;Checkbox id={"{"}x{"}\n"} checked={"{"}this.state.checked.indexOf(x) != -1{"}\n"} onClick={"{"}() => this.handleClick(x){"}\n"} /&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
