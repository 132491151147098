import React from "react";

export default class Alerts extends React.Component {
  render() {
    return (
      <div id="alerts">
        <h1>Alerts</h1>
        <img
          src={require("./images/alerts.png")}
          alt="switch"
          className="img200"
        />
        <p>
          In some cases we use a custom alert, it is very similar to our delete
          modal. To use it you must first import it.
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/alert.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>text (Alert text)</li>
          <li>btn (Button)</li>
        </ul>

        <p>
          When you pass in buttons, you should pass in the{" "}
          <a
            onClick={() => this.props.changeView("buttons")}
            style={{ color: "#1978c8" }}
          >
            Button
          </a>{" "}
          component.
        </p>

        <div className="code">
          <span>
            &lt;Alert text={"{"}"ALERT TEXT"{"}\n"} btn={"{"}&lt;Button /&gt;
            {"}\n"} /&gt;
          </span>
        </div>

        <br />

        <h3>alert-custom</h3>
        <img
          src={require("./images/alert_custom.png")}
          alt="switch"
          style={{ width: "800px" }}
        />
        <p>Work in progress...</p>
        <br />
      </div>
    );
  }
}
