import React from "react";

export default class CreativeList extends React.Component {
  render() {
    return (
      <div id="creative_list">
        <h1>Creative List</h1>
        <img
          src={require("./images/creative_list.png")}
          alt="switch"
          className="img200"
        />
        <p>
          To use the creative list component you must first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/creative_list.jsx' %{"}\n"}
          </code>
        </div>
        <br />
        <p>It consists of two separate pieces:</p>
        <ul>
          <li>CreativeList</li>
          <li>CreativeItem</li>
        </ul>
        <br />
        <p>And it can take the following prop types:</p>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "15px" }}>
            <p>CreativeList:</p>
            <ul>
              <li>listItems</li>
            </ul>
          </div>
          <div style={{ padding: "15px", borderLeft: "1px solid black" }}>
            <p>CreativeItem:</p>
            <ul>
              <li>itemID</li>
              <li>readyToDelete (boolean)</li>
              <li>onItemClick</li>
              <li>numBox</li>
              <li>imgSrc</li>
              <li>imgAlt</li>
              <li>onNumboxClick</li>
              <li>onCenterClick</li>
              <li>onBottomClick</li>
              <li>onEditClick</li>
              <li>subBox</li>
              <li>includeNum (boolean)</li>
              <li>includeBottom (boolean)</li>
            </ul>
          </div>
        </div>
        <br />
        <p>
          When you add CreativeItem's into the props for the CreativeList, you
          must wrap them inside of a &lt;ul&gt; element.
        </p>

        <div className="code">
          <span>&lt;CreativeList listItems={"{"}</span>
          <br />
          <span className="mar20">&lt;ul&gt;</span>
          <br />
          <span className="mar40">&lt;CreativeItem</span>
          <br />
          <span className="mar60">
            imgSrc={"{"}"https://placeholder.com{"}\n"}
          </span>
          <br />
          <span className="mar60">
            includeNum={"{"}true{"}\n"}
          </span>
          <br />
          <span className="mar60">
            includeBottom={"{"}true{"}\n"}
          </span>
          <br />
          <span className="mar60">
            numBox={"{"}"1"{"}\n"}
          </span>
          <br />
          <span className="mar60">
            subBox={"{"}"TEST TEST TEST"{"}\n"}
          </span>
          <br />
          <span className="mar60">
            readytoDelete={"{"}this.state.readytoDelete{"}\n"}
          </span>
          <br />
          <span className="mar60">
            onCenterClick={"{"}() => this.function(){"}\n"}
          </span>
          <br />
          <span className="mar60">
            onNumboxClick={"{"}() => this.function(){"}\n"}
          </span>
          <br />
          <span className="mar60">
            onBottomClick={"{"}() => this.function(){"}\n"}
          </span>
          <br />
          <span className="mar60">
            onEditClick={"{"}() => this.function(){"}\n"}
          </span>
          <br />
          <span className="mar40">/&gt;</span>
          <br />
          <span className="mar20">&lt;/ul&gt;</span>
          <br />
          <span>{"}\n"} /&gt;</span>
        </div>
        <br />
      </div>
    );
  }
}
