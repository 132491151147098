import React from "react";

export default class FAQ extends React.Component {
  render() {
    return (
      <div id="faq">
        <h1>FAQ</h1>
        <h3>Q. What React version do we use?</h3>
        <h3 className="answer">A. We use version 15.3.2</h3>
        <br />
        <h3>Q. Do I have to use these components?</h3>
        <h3 className="answer">A. Yes.</h3>
        <br />
        <h3>Q. Can I edit these components?</h3>
        <h3 className="answer">A. No, do not edit these components.</h3>
        <br />
        <h3>
          Q. I have an idea for how to improve a component, what should I do?
        </h3>
        <h3 className="answer">
          A. Consult with Joshua and he can add your improvements.
        </h3>
        <br />
        <h3>
          Q. I found a problem in one of the components and it needs updated,
          what should I do?
        </h3>
        <h3 className="answer">A. Consult with Joshua and he will fix it.</h3>
        <br />
        <h3>Q. What happens if I edit these components anyway?</h3>
        <h3 className="answer">A. Joshua will be very upset with you.</h3>
        <br />
        <h3>Q. I made a new component, can we add it to this library?</h3>
        <h3 className="answer">
          A. absolutely! Consult with Joshua and he can write some documentation
          for it.
        </h3>
        <br />
        <h3>
          Q. I need to make a new page, can I use HTML and vanilla Javascript?
        </h3>
        <h3 className="answer">A. No, you must use React.</h3>
        <br />
        <h3>Q. Can I use JQuery?</h3>
        <h3 className="answer">A. No, please use React.</h3>
        <br />
        <h3>
          Q. I made a new page using HTML and vanilla Javascript and/or JQuery,
          can you update this page using your components?
        </h3>
        <h3 className="answer">
          A. No, you need to create it using the components and react from the beginning.
        </h3>
        <br />
        <h3>
          Q. I edited styles.full.css but my changes did not take effect! What
          should I do?
        </h3>
        <h3 className="answer">
          A. Our app does not read style.full.css, it only reads the minified v2/style.css.
          Style.full.css exists for your convenience to read what the styles
          are, do not edit it.
        </h3>
        <br />
        <h3>Q. Can I edit the SCSS files?</h3>
        <h3 className="answer">
          A. No, do not edit the SCSS files. If you are a frontend or fullstack
          engineer you may edit the SCSS files if necessary, but please have
          Joshua review your code.
        </h3>
        <br />
        <h3>
          Q. I need to write styles for a new component. Where should I write
          these styles?
        </h3>
        <h3 className="answer">
          A. If you are a frontend or full stack engineer you can write these new styles into the SCSS files and have Joshua review. it. Otherwise while you are creating new styles you can write them between style
          tags in your HTML template and then submit it to Joshua to add to our
          main style sheets before you make your PR.
        </h3>
        <br />
        <h3>Q. Can I add to styles.less?</h3>
        <h3 className="answer">
          A. No, do not edit styles.less. We will be phasing this out of our
          project, do not add to it.
        </h3>
        <br />
        <h3>
          Q. My task is to fix a CSS problem, can I edit the original files?
        </h3>
        <h3 className="answer">
          A. Yes but Joshua must review your code before it can be merged.
        </h3>
        <br />
      </div>
    );
  }
}
