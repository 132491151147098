import React from "react";

export default class Inputs extends React.Component {
  render() {
    return (
      <div id="inputs">
        <h1>Inputs</h1>
        <h3>Text</h3>
        <img
          src={require("./images/input_text.png")}
          alt="text input"
          style={{ width: "600px" }}
        />
        <p>
          To use the input component you must first add an include statement in
          your file:
        </p>
        <div className="code">
          <span>
            {"{"}% include "common/react/input.jsx" %{"}\n"}
          </span>
        </div>

        <br />

        <p>It can accept the following prop types:</p>
        <ul>
          <li>className</li>
          <li>id</li>
          <li>type (default is "text")</li>
          <li>maxLength</li>
          <li>onChange</li>
          <li>name</li>
          <li>value</li>
          <li>placeholder</li>
        </ul>

        <p>
          Do these prop types seem familiar? The idea is to use this component
          like a normal input, but the default styles are automatically applied.
          If you pass in a className via props it will add that className to the
          list.
        </p>

        <div className="code">
          <span>
            &lt;Input id={"{"}"myID"{"}\n"} name={"{"}"description"{"}\n"}{" "}
            value=
            {"{"}this.state.descriptionVal{"}\n"} onChange={"{"}
            this.handleChange
            {"}\n"} /&gt;
          </span>
        </div>

        <br />

        <h3>Textarea</h3>
        <img
          src={require("./images/textarea.png")}
          alt="textarea"
          style={{ width: "600px" }}
        />
        <p>
          To use the input component you must first add an include statement in
          your file:
        </p>
        <div className="code">
          <span>
            {"{"}% include "common/react/textarea.jsx" %{"}\n"}
          </span>
        </div>

        <br />

        <p>It can accept the following prop types:</p>
        <ul>
          <li>className</li>
          <li>id</li>
          <li>maxLength</li>
          <li>rows</li>
          <li>cols</li>
          <li>onChange</li>
          <li>name</li>
          <li>value</li>
          <li>placeholder</li>
        </ul>

        <p>
          Similarly to the input component, the idea is to use it like a normal
          textarea element with our default styles already applied.
        </p>

        <div className="code">
          <span>
            &lt;TextArea id={"{"}"myID"{"}\n"} name={"{"}"userReview"{"}\n"}{" "}
            value=
            {"{"}this.state.userReviewVal{"}\n"} onChange={"{"}
            this.handleChange
            {"}\n"} /&gt;
          </span>
        </div>

        <br />

        <h3>Date</h3>
        <img
          src={require("./images/input_date.png")}
          alt="date input"
          style={{ width: "600px" }}
        />
        <p>To be added later...</p>

        <br />
      </div>
    );
  }
}
