import React from "react";
import ReactDOM from "react-dom";

import Home from "./Home.jsx";
import Alerts from "./Alerts.jsx";
import BadgesIcons from "./BadgesIcons.jsx";
import Buttons from "./Buttons.jsx";
import Checkbox from "./Checkbox.jsx";
import Color from "./Color.jsx";
import CreativeList from "./CreativeList.jsx";
import DetailBlock from "./DetailBlock.jsx";
import Inputs from "./Inputs.jsx";
import Label from "./Label.jsx";
import FAQ from "./faq.jsx";
import Font from "./Font.jsx";
import Modals from "./Modals.jsx";
import Pagination from "./Pagination.jsx";
import Popover from "./Popover.jsx";
import RadioList from "./RadioList.jsx";
import RightMenu from "./RightMenu.jsx";
import Select from "./Select.jsx";
import Sidebar from "./Sidebar.jsx";
import SmartList from "./SmartList.jsx";
import Switch from "./Switch.jsx";
import Toolbar from "./Toolbar.jsx";
import Tooltip from "./Tooltip.jsx";
import TristateSwitch from "./TristateSwitch.jsx";
import QuickStart from "./QuickStart.jsx";

import "./styles.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "home"
    };
  }

  changeView(selectedView) {
    this.setState({ view: selectedView });
  }

  render() {
    return (
      <div className="app">
        <Sidebar changeView={this.changeView.bind(this)} />
        <div className="main">
          {this.state.view === "home" ? <Home /> : ""}
          {this.state.view === "alerts" ? <Alerts /> : ""}
          {this.state.view === "badges_icons" ? <BadgesIcons /> : ""}
          {this.state.view === "buttons" ? <Buttons /> : ""}
          {this.state.view === "checkbox" ? <Checkbox /> : ""}
          {this.state.view === "color" ? <Color /> : ""}
          {this.state.view === "creative_list" ? <CreativeList /> : ""}
          {this.state.view === "detail_block" ? <DetailBlock /> : ""}
          {this.state.view === "inputs" ? <Inputs /> : ""}
          {this.state.view === "labels" ? <Label /> : ""}
          {this.state.view === "faq" ? <FAQ /> : ""}
          {this.state.view === "font" ? <Font /> : ""}
          {this.state.view === "modals" ? (
            <Modals changeView={this.changeView.bind(this)} />
          ) : (
            ""
          )}
          {this.state.view === "pagination" ? <Pagination /> : ""}
          {this.state.view === "popover" ? <Popover /> : ""}
          {this.state.view === "radio_list" ? <RadioList /> : ""}
          {this.state.view === "right_menu" ? <RightMenu /> : ""}
          {this.state.view === "select" ? <Select /> : ""}
          {this.state.view === "smart_list" ? <SmartList /> : ""}
          {this.state.view === "switch" ? <Switch /> : ""}
          {this.state.view === "toolbar" ? <Toolbar /> : ""}
          {this.state.view === "tooltip" ? <Tooltip /> : ""}
          {this.state.view === "tristate-switch" ? <TristateSwitch /> : ""}
          {this.state.view === "quick_start" ? <QuickStart /> : ""}
        </div>
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
