import React from "react";

export default class Popover extends React.Component {
  render() {
    return (
      <div id="popover">
        <h1>Popover</h1>
        <img
          src={require("./images/popover.png")}
          alt="switch"
          className="img100"
        />
        <p>
          The popover is used to reveal a textblock on hover, it is similar to a
          tooltip both in its styling and its behavior. To use it, you must
          first import it like this:
        </p>
        <div className="code">
          <code>
            {"{"}% include 'common/react/popover.jsx' %{"}\n"}
          </code>
        </div>

        <p>And it can take the following prop types:</p>
        <ul>
          <li>hover (Text to hover over)</li>
          <li>pop (Text to display when hovered)</li>
        </ul>

        <div className="code">
          <span>
            &lt;Popover hover={"{"}"Text to hover"{"}\n"} pop={"{"}
            "Text to display on hover"{"}\n"} /&gt;
          </span>
        </div>
        <br />
      </div>
    );
  }
}
